<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="phone"
        name="phone"
        label="手机号"
        placeholder="请输入已注册的手机号"
        disabled
      />
      <van-field
        v-model="name"
        name="name"
        label="名称"
        placeholder="请输入"
      />
      <van-field name="uploader" label="头像">
        <template #input>
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field name="type" label="是否技师">
        <template #input>
          <van-radio-group v-model="type" direction="horizontal">
            <van-radio :name="0">否</van-radio>
            <van-radio :name="1">是</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="type" label="是否销售">
        <template #input>
          <van-radio-group v-model="sale" direction="horizontal">
            <van-radio :name="0">否</van-radio>
            <van-radio :name="1">是</van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import { upload } from '@/util/util'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      staffId: useRoute.query.staffId,
      phone: '',
      name: '',
      avatar: '',
      type: '',
      sale: '',
      fileList: [],
      storeId: Cookies.get('storeId'),
      qiniu: {}
    })
    const onSubmit = (values) => {
      if (values.uploader.length === 0) {
        Toast('请上传一个图片')
        return false
      }
      state.avatar = values.uploader[0].url
      delete values.uploader
      Toast.loading({ forbidClick: true, duration: 0 })
      post('/staff.update', {
        staffId: state.staffId,
        name: state.name,
        avatar: state.avatar,
        type: parseInt(state.type),
        sale: parseInt(state.sale)
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
          useRouter.go(-1)
        } else {
          Toast(res.msg)
        }
      })
    }
    const beforeRead = (file) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
        Toast('图片类型支持: jpg,png,gif')
        return false
      }
      return true
    }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
        console.log(res.img)
      })
    }
    const init = () => {
      post('/staff.get', {
        staffId: state.staffId
      }).then(res => {
        state.phone = res.data.user.phone
        state.name = res.data.name
        state.type = res.data.type
        state.sale = res.data.sale
        if (res.data.avatar !== '') {
          state.fileList = []
          state.fileList.push({
            url: res.data.avatar,
            isImage: true
          })
        }
      })
      post('/util.getQiniuToken', {
      }).then(res => {
        state.qiniu = res.data
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      beforeRead,
      afterRead
    }
  }
}
</script>

<style scoped>
</style>
